import React from "react";
/* eslint-disable max-len */

import { useStaticQuery, graphql, Link } from "gatsby";

import SEO from "../components/Seo";
import BlogPage from "../components/template-parts/BlogPage";

function Meditacao() {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "bgImage/bg-meditacao.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const bannerPage = banner.childImageSharp.fluid;
  const content = {
    title: `Meditação`,
    featuredImage: bannerPage,
  };
  return (
    <>
      <SEO
        title={content.title}
        keywords="helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação, fmusp"
        description="Na Medicina Chinesa, não há divisão entre corpo e mente: um
        desequilíbrio reflete-se igualmente nos dois e o tratamento traz
        benefícios físicos e mentais. Muitas vezes um paciente procura na
        acupuntura a cura para uma dor lombar. Após algumas sessões, não só a
        dor melhorou, como também ele percebe que está mais calmo e dormindo
        melhor."
        type="article"
      />
      <BlogPage content={content}>
        <p>
          A ligação entre mente e corpo ou, até mesmo, a unidade funcional mente-corpo é objeto de
          grande interesse nos dias de hoje. Existem fortes evidências, por exemplo, de que fatores
          de estresse podem contribuir para o surgimento de doenças do coração ou do aparelho
          digestivo. Um paciente deprimido pode apresentar alterações importantes no seu sistema
          imunológico, que eventualmente vão interferir em diversas doenças, aumentando-lhes o tempo
          de convalescença ou facilitando o aparecimento de gripes e resfriados. Também a via
          inversa pode ser observada: quando estamos doentes, não temos a mesma clareza de idéias, a
          mesma disposição e a mesma atitude diante dos desafios da vida.
        </p>

        <p>
          Na <Link to="/medicina-tradicional-chinesa">Medicina Chinesa</Link>, não há divisão entre
          corpo e mente: um desequilíbrio reflete-se igualmente nos dois e o tratamento traz
          benefícios físicos e mentais. Muitas vezes um paciente procura na acupuntura a cura para
          uma dor lombar. Após algumas sessões, não só a dor melhorou, como também ele percebe que
          está mais calmo e dormindo melhor. Outro paciente pode procurar tratamento para uma
          depressão leve e dando-se conta de que, além de estar mais bem disposto, não tem mais
          infecções urinárias de repetição.&nbsp;
        </p>

        <p>
          Além disso, a <Link to="/medicina-tradicional-chinesa">Medicina Chinesa</Link> estuda a
          interação do homem com o seu meio-ambiente, bem como o microcosmo, o macrocosmo e a
          constante dinâmica existente entre eles.&nbsp;
        </p>

        <h2>Mens sana in corpore sano</h2>

        <p>
          A abordagem holística do ser humano vem crescendo rapidamente. Na década de 60, o
          neurocientista, Karl Pribam, utilizou-se do modelo holográfico para descrever a disposição
          da memória no cérebro. Mais tarde, propôs que o modo de funcionamento cerebral seria uma
          mistura deste modelo holográfico e do modelo analógico. Visão holística quer dizer visão
          do todo. Cada parte deste todo é dinâmica e criativa; portanto, não se podem isolar as
          partes para compreender o todo, assim sendo, o todo é maior que o conjunto das partes. E o
          que é o todo? O todo pode ser uma célula, um órgão, um sistema, um organismo vivo, um
          organismo no seu meio ambiente, a sociedade, o cosmos… Ou seja, para entender o que
          estamos estudando aqui, precisamos observar que os organismos vivos estão em constante
          interação com o meio-ambiente. Na visão do todo, a mente e o corpo operam como uma unidade
          funcional que interage com o meio.
        </p>

        <p>
          Partindo da visão holística, atualmente, não só a Medicina se dedica a entender como os
          processos emocionais afetam o funcionamento do corpo, mas também a Psicologia têm estudado
          a forma como o corpo pode influenciar a mente. Guardamos no corpo nossa história, como uma
          árvore que, ao crescer, depara-se com uma cerca e desvia-se para continuar rumo ao alto.
          Talvez a cerca venha a ser removida, mas após muitos anos ainda poderemos ver que o tronco
          daquela árvore não é reto. A árvore conta-nos a sua história. No corpo guardamos memórias
          de vivências antigas e profundas, que são às vezes pré-verbais, da nossa primeira
          infância. No corpo guardamos também as dores e as alegrias das vivências atuais. No corpo
          reside um potencial caminho para o entendimento da mente e da história pessoal de cada um.
        </p>
      </BlogPage>
    </>
  );
}

export default Meditacao;
